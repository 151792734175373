import React from "react";
import MblNav from "./MblNav";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="mainNav">
      <MblNav />
      <div className="container">
        <div className="navContentDiv">
          <ul className="navContent d-none d-lg-flex">
            <li>PUNJAB</li>
            <li>SINDH</li>
            <li>KP/FATA</li>
            <li>BALOCHISTAN</li>
          </ul>
          <i
            className="bi bi-list d-block d-lg-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBackdrop"
            aria-controls="offcanvasWithBackdrop"
          ></i>
          <div className="right-content">
            <button className="nav-btn ">DOWNLOAD FULL BOOK</button>
            <p className="d-none d-lg-block" style={{ marginBottom: "0px" }}>
              SHAIR
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
