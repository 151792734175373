import React from "react";
import "./Stories.css";

// const data = [
// {
//     id:1,
//     text:""
// },
// {
//     id:2,
//     text:""
// },
// {
//     id:3,
//     text:""
// },
// ]

const Stories = () => {
  return (
    <div className="stories-main-div">
      <div className="container stories">
        <h2 className="stori-title">
          Stories of <br />
          Incarceration
        </h2>
        <p className="stories-text">
          “When the judge came, he asked if I was having any difficulties. This
          was the sessions judge — during my first month [in jail]. I said I
          could see the sunlight in the hallway, but it didn’t reach my cell. I
          asked to be moved, so I could get some sun, but this was denied.”
        </p>
        <div className="row row-cols-1 row-cols-md-3 g-3">
          <div className="col">
            <div className="stori-card overflow-auto container py-3">
              <h3 className="card-title">
                <span style={{ color: "#C1563A" }}>Farooq’s story:</span>{" "}
                Invisibilising Identity
              </h3>
              <p className="card-text">
                “Upon entry to jail, prisoners are asked how they identified
                according to caste, and if [a person from a minority faith]
                provided association such as jat or malik, which is a common way
                of identification in Pakistan, they would be told, ‘no, you are
                [from the minority faith.]’ [Our faith] becomes our caste… for
                us religion is noted in place of caste.” <br />
                <br />
                In Jhelum, our only association was with the other minority
                prisoners, so there were no clashes between us. Similarly, in
                Gujrat [District Jail], we were those trapped under blasphemy
                allegations – so when you’re all put together like this, there’s
                little discrimination that takes place
              </p>
            </div>
          </div>
          <div className="col">
            <div className="stori-card overflow-auto container py-3">
              <h3 className="card-title">
                <span style={{ color: "#C1563A" }}>Farooq’s story:</span>{" "}
                Dispossessed in Detention
              </h3>
              <p className="card-text">
                [In Adiala] after about a month and half, we asked to have
                leisure time out of the cell – because the other prisoners were
                allowed out for an hour in the morning and evening. Blasphemy
                prisoners only receive leisure time once every month, or to a
                month and a half, sometimes two months or three, but I wasn’t
                getting this provision and wanted it too. Permission for this
                and for everything else in the jail comes from the
                superintendent police – and this was especially the case of
                those who were said to have committed blasphemy. The area that
                we would have liked to walk around in would have been [ideally]
                contained within the section
              </p>
            </div>
          </div>
          <div className="col">
            <div className="stori-card overflow-auto container py-3">
              <h3 className="card-title">
                <span style={{ color: "#C1563A" }}>Sohail’s story:</span>{" "}
                Rehabilitation comes to the majority faith
              </h3>
              <p className="card-text">
                When I first went to jail, my religion became a big problem – I
                was tortured, and called a chura in jail – no one ever called me
                by my name. My co-accuseds were Muslim, and so I was blamed for
                corrupting and involving them in my crime. <br />
                <br />
                The day I entered prison, I was told ‘you’re a chura, go clean
                the gutter.’ In jail, Christian prisoners were separated from
                one another. It was understood that each Christian in each area
                or barrack was responsible for looking after the cleaning, the
                gutter and toilets, and if you don’t do this work, you’ll be
                skinned. So I had to do the sweeping. If someone is from a
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stories;
