import React from "react";
import "./Diversion.css";
const Diversion = () => {
  return (
    <div className="diversion-main-div">
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2">
          <div className="col">
          {/* <hr className="hr-line"/> */}
            <div className="divided-punjab">
              <div>
                <h2 className="dp-text">UNDIVIDED PUNJAB</h2>
                <p className="dp-paragraph">
                  In Punjab the forms of discrimination and criminalization that
                  sectarian and religious minority groups face can be traced
                  back to colonial anthropological practices of topographically
                  identifying people of the region by religion. Carried out
                  excessively across undivided Punjab, this lay the groundwork
                  for an understanding of the western portion of the province
                  as, separately, cartographically Muslim{" "}
                </p>
              </div>
              <div>
                <h2 className="dp-text">UNDIVIDED PUNJAB</h2>
                <p className="dp-paragraph">
                  Following Partition, the influx of Muslim refugees and the
                  departure of Hindus and Sikhs for India resulted in
                  demographic changes that saw Sunnis become the vast majority,
                  and a majoritarian Sunni identity was also fomented.
                </p>
              </div>
              <div>
                <h2 className="dp-text">THE PRESENT</h2>
                <p className="dp-paragraph">
                  Today, while socio-economic disenfranchisement is a major
                  reason for high incarceration rates across the country, in the
                  province, the back-story to much criminal activity that
                  defence lawyers sift through, such as murder and robbery, take
                  place as a result of community-based tussles over limited
                  resources. For those members of the working class who belong
                  to minority backgrounds the situation is even worse, as they
                  are vulnerable to the scapegoating of their identities as
                  being in violation of the majority faith, which becomes the
                  platform on which to dispute rights to employment, property,
                  and political participation.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="content-gap">
              <h2 className="detention-tex">
                Detention through Colonial and Postcolonial Times
              </h2>
              <img
                width="100%"
                src={require("../../assets/detention.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* <hr className="l-1" />
      <div>
        <hr className="l-2" />
      </div>
      <div>
        <img
          className="circle-1"
          src={require("../../assets/circle.png")}
          alt=""
        />
      </div>
      <div>
        <img
          className="circle-2"
          src={require("../../assets/circle.png")}
          alt=""
        />
      </div>
      <div>
        <img
          className="circle-3"
          src={require("../../assets/circle.png")}
          alt=""
        />
      </div> */}
    </div>
  );
};

export default Diversion;
