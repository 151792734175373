import React from "react";
import Audio from "./Audio/Audio";
import "./Chapter.css";

const Chapter = () => {
  return (
    <div className="chapter-main-div">
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 g-4">
          <div className="col">
            <div className="col-content">
              <div className="col-images d-none d-md-flex">
                <img src={require("../../assets/mike.png")} alt="" />
                <img src={require("../../assets/list.png")} alt="" />
                <img src={require("../../assets/fly.png")} alt="" />
                <img src={require("../../assets/enterprise.png")} alt="" />
              </div>
              <div>
                <h3 className="chapter-text" id="chapter">
                  CHAPTER <span style={{ color: "#C1563A" }}>I</span>
                </h3>
                <h2 className="punjab-text">
                  <span style={{ color: "#C1563A" }}>Punjab</span>: Privileged
                  Majoritarianism,
                  <br /> Disenfranchised Minorities
                </h2>
                <p className="paragraph">
                  Ibbetson (1881) mapped the undivided Punjab into distinct,
                  enumerated religio-ethnic portions, as if its very topography
                  was a cartography for the racial, cultural, linguistic, and
                  tribal myriad he hoped to classify.
                </p>
                <p className="paragraph">
                  In Punjab, religious communitarian identity further
                  complicates rural and working class experiences with law
                  enforcement, criminal legal procedure, the courts, and
                  imprisonment, that point to a long history about the
                  construction of privileged Sunni Muslim demographic majority,
                  pushing minority groups to the fringes of a sense of belonging
                  in the province.
                </p>
                <p className="paragraph">
                  Today, working class minority groups face some of the sharpest
                  forms of religious discrimination in the country, including
                  criminalisation under Bhutto and Zia era modifications to the
                  constitution and colonial blasphemy laws, respectively.
                </p>
                <div className="audio-podcast container">
                  <h2 className="podcast-text">LISTEN TO OUR PODCAST</h2>
                  <p className="podcast-paragraph">
                    Writer Radha Shah speaks about her experiences of writing
                    the book and hearing the stories of former prisoners
                  </p>
                  <Audio />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <img
              width="100%"
              // height="885px"
              src={require("../../assets/map.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chapter;
