import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./Audio.css";

const Audio = () => {
  return (
    <div className="">
      {/* <h1>Hello, audio player!</h1> */}
      <AudioPlayer
        src="https://hanzluo.s3-us-west-1.amazonaws.com/music/wuyuwuqing.mp3"
        volume={0.5}
        // Try other props!
      />
      {/* <p>
        <a
          href="https://github.com/lhz516/react-h5-audio-player"
          target="_blank"
        >
          Read Docs on Github
        </a>
      </p> */}
    </div>
  );
};

export default Audio;
