import React from "react";
import "./Prisons.css";

const Prisons = () => {
  return (
    <div className="prosons-main-div">
      <div className="container">
        <h2 className="prisons-title">
          <span style={{ color: "#C1563A" }}>Punjab’s Prisons: </span>
          <br /> In Numbers
        </h2>
        <div className="row row-cols-1 row-cols-md-2 py-3">
          <div className="col">
            <p className="prisons-text">
              A 2019 study by the British Home Office on how to assess whether
              to grant asylum to prisoner refugee complainants, found that
              prisoners from minority communities and those accused of blasphemy
              suffered worse among the majority population in already poor
              conditions characterized by overcrowding and insufficient access
              to healthcare and nutritious food. This Pakistan-wide assessment
              included firsthand accounts from minority prisoners attesting to
              abusive and violent treatment, even poorer facilities, lengthy
              solitary confinement (violating national and international
              guidelines). The latter was justified by the government as a
              safety measure to protect minority prisoners.
            </p>
          </div>
          <div className="col">
            <img
              className="mb-4"
              width="100%"
              src={require("../../assets/timeline.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prisons;
