import React from "react";
import "./MblNav.css";

const MblNav = () => {
  return (
    <div>
      <div
        className="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasWithBackdrop"
        aria-labelledby="offcanvasWithBackdropLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="offconvas-ul">
            <li>PUNJAB</li>
            <hr />
            <li>SINDH</li>
            <hr />
            <li>KP/FATA</li>
            <hr />
            <li>BALOCHISTAN</li>
          </ul>
          <button className="shair-btn">SHAIR</button>
        </div>
      </div>
    </div>
  );
};

export default MblNav;
