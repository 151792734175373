import React from "react";
import Carousel from "../../components/Carousel/Carousel";
import Chapter from "../../components/Chapter/Chapter";
import Diversion from "../../components/Diversion/Diversion";
import Navbar from "../../components/Navbar/Navbar";
import Prisons from "../../components/Prisons/Prisons";
import Stories from "../../components/Stories/Stories";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Carousel />
      <Chapter />
      <Diversion />
      <Stories />
      <Prisons />
    </div>
  );
};

export default Home;
