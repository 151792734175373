import React from "react";
import "./Carousel.css";
const Carousel = () => {
  return (
    <div className="carousel-bg">
      <div className="container">
        <div className="carousel-content">
          <p className="p-text">
            “Two kinds of people can go into a grave, the dead and the living.
            For the dead a grave is at the graveyard, for the living it is at a
            jail.”
          </p>
          <div className="row row-cols-1 row-cols-md-2 g-5">
            <div className="col-md-3">
              <img width="100%" src={require("../../assets/jp-1.png")} alt="" />
            </div>
            <div className="col-md-9">
              <h2>Prisons as Microcosms of Provincial and Federal Realities</h2>
              <p>
                Explore the stories of 20 former under-trial and convicted
                prisoners across Pakistan’s prison system in the four provinces
                of Punjab, Sindh, Khyber Pakhtunkhwa and Balochistan.
              </p>
              <p>
                Their experiences with arrest and incarceration provide a
                historic and contemporary lens to understand the country’s penal
                system as part of the legacy of colonial governance in the
                Indian subcontinent, the creation of Pakistan, and current day
                political debates that preoccupy the country under the umbrella
                of insecurity.
              </p>
            </div>
          </div>
          <div className="down-arrow-carousel">
            <a href="#chapter" className="bi bi-arrow-down"></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
